import React from 'react';
import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import {
  Image as ChakraImage,
  Box,
  Container as ContainerChakra,
  Text,
  Flex,
  Button,
  SimpleGrid,
  Center,
  Heading,
  Stack
} from '@chakra-ui/react';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import One from '../images/advantages/one.svg';
import Two from '../images/advantages/two.svg';
import Three from '../images/advantages/three.svg';
import Four from '../images/advantages/four.svg';
import Five from '../images/advantages/five.svg';
import Six from '../images/advantages/six.svg';

import { TitleWithColors } from '../components/cms/TitleWithColors';

const ConceptItem = ({ title, color = 'brand.400', source }) => {
  return (
    <Box px={5} py={10} backgroundColor={color}>
      <Center mb={4}>
        <ChakraImage width="25%" objectFit="cover" src={source} />
      </Center>

      <Box textAlign="center">
        <Text
          as="span"
          fontWeight="bold"
          fontSize={{ base: 'md', lg: 'xl' }}
          lineHeight="short"
          textTransform="uppercase"
        >
          {title}
        </Text>
      </Box>
    </Box>
  );
};
const deepPage = ({ data }) => {
  const { data: cms } = data.prismicPageProfonde;
  return (
    <Layout>
      <SEO title={cms.titre_seo.text} description={cms.meta_description.text} />
      <Stack direction="column" spacing={20}>
        <Flex as="header">
          <Center flex={1}>
            <ContainerChakra maxWidth="80%">
              <TitleWithColors title={cms.titre_generale.text} />
              <Text
                fontSize="3xl"
                fontWeight="bold"
                mb={4}
                dangerouslySetInnerHTML={{
                  __html: cms.sous_titre_intro.text
                }}
              />

              <Box
                fontWeight="medium"
                fontSize="lg"
                dangerouslySetInnerHTML={{
                  __html: cms.description_intro.html
                }}
              />
            </ContainerChakra>
          </Center>

          <Center flex={1} display={{ base: 'none', lg: 'block' }}>
            <GatsbyImage
              as={ChakraImage}
              height="100vh"
              width="100%"
              fluid={cms.img_intro.localFile?.childImageSharp.fluid}
            />
          </Center>
        </Flex>

        <Box position="relative">
          <TitleWithColors
            as="h3"
            fontSize="3xl"
            fontWeight="bold"
            mb={8}
            textAlign="center"
            dangerouslySetInnerHTML={{
              __html: cms.titre_section_1.text
            }}
          />
          <Text fontSize="lg" fontWeight="medium" textAlign="center" mb={10}>
            {cms.description_section_1.text}
          </Text>
          <ContainerChakra maxWidth="8xl">
            <SimpleGrid
              columns={{ base: 2, md: 3 }}
              gap="1em"
              borderRadius="12px"
              overflow="hidden"
            >
              {cms.titre_carre_1.text && (
                <ConceptItem
                  radiusLeft
                  title={cms.titre_carre_1.text}
                  color="#FDDA74"
                  source={One}
                />
              )}
              {cms.titre_carre_2.text && (
                <ConceptItem
                  title={cms.titre_carre_2.text}
                  color="#FCCE46"
                  source={Two}
                />
              )}
              {cms.titre_carre_3.text && (
                <ConceptItem
                  radiusRight
                  title={cms.titre_carre_3.text}
                  source={Three}
                />
              )}
              {cms.titre_carre_4.text && (
                <ConceptItem
                  radiusLeft
                  title={cms.titre_carre_4.text}
                  color="#FDDA74"
                  source={Four}
                />
              )}
              {cms.titre_carre_5.text && (
                <ConceptItem
                  title={cms.titre_carre_5.text}
                  color="#FCCE46"
                  source={Five}
                />
              )}
              {cms.titre_carre_6.text && (
                <ConceptItem
                  radiusRight
                  title={cms.titre_carre_6.text}
                  source={Six}
                />
              )}
            </SimpleGrid>
          </ContainerChakra>
        </Box>
        <Box position="relative">
          <TitleWithColors
            as="h3"
            fontSize="3xl"
            fontWeight="bold"
            mb={8}
            textAlign="center"
            dangerouslySetInnerHTML={{
              __html: cms.titre_section_2.text
            }}
          />
          <Text fontSize="lg" fontWeight="medium" textAlign="center" mb={10}>
            {cms.description_generale_section_2.text}
          </Text>
          <ContainerChakra maxWidth="5xl">
            <Stack direction="column" spacing={8}>
              {
                (cms.titre_rectangle_1.text,
                cms.description_rectangle_1.text && (
                  <Stack direction="column" spacing={6} shouldWrapChildren>
                    <Heading
                      as="h5"
                      backgroundColor="brand.400"
                      fontSize="xl"
                      display="inline-flex"
                      px={3}
                      py={1}
                    >
                      {cms.titre_rectangle_1.text}
                    </Heading>
                    <Text fontSize="lg">
                      {cms.description_rectangle_1.text}
                    </Text>
                    {cms.exemple_rectangle_1.text && (
                      <TitleWithColors
                        as="p"
                        fontSize="xl"
                        dangerouslySetInnerHTML={{
                          __html: cms.exemple_rectangle_1.text
                        }}
                      />
                    )}
                  </Stack>
                ))
              }
              {
                (cms.titre_rectangle_2.text,
                cms.description_rectangle_2.text && (
                  <Stack direction="column" spacing={6} shouldWrapChildren>
                    <Heading
                      as="h5"
                      backgroundColor="brand.400"
                      fontSize="xl"
                      display="inline-flex"
                      px={3}
                      py={1}
                    >
                      {cms.titre_rectangle_2.text}
                    </Heading>
                    <Text fontSize="lg">
                      {cms.description_rectangle_2.text}
                    </Text>
                    {cms.exemple_rectangle_2.text && (
                      <TitleWithColors
                        as="p"
                        fontSize="xl"
                        dangerouslySetInnerHTML={{
                          __html: cms.exemple_rectangle_2.text
                        }}
                      />
                    )}
                  </Stack>
                ))
              }
              {
                (cms.titre_rectangle_3.text,
                cms.description_rectangle_3.text && (
                  <Stack direction="column" spacing={6} shouldWrapChildren>
                    <Heading
                      as="h5"
                      backgroundColor="brand.400"
                      fontSize="xl"
                      display="inline-flex"
                      px={3}
                      py={1}
                    >
                      {cms.titre_rectangle_3.text}
                    </Heading>
                    <Text fontSize="lg">
                      {cms.description_rectangle_3.text}
                    </Text>
                    {cms.exemple_rectangle_3.text && (
                      <TitleWithColors
                        as="p"
                        fontSize="xl"
                        dangerouslySetInnerHTML={{
                          __html: cms.exemple_rectangle_3.text
                        }}
                      />
                    )}
                  </Stack>
                ))
              }
              {
                (cms.titre_rectangle_4.text,
                cms.description_rectangle_4.text && (
                  <Stack direction="column" spacing={6} shouldWrapChildren>
                    <Heading
                      as="h5"
                      backgroundColor="brand.400"
                      fontSize="xl"
                      display="inline-flex"
                      px={3}
                      py={1}
                    >
                      {cms.titre_rectangle_4.text}
                    </Heading>
                    <Text fontSize="xl">
                      {cms.description_rectangle_4.text}
                    </Text>
                    {cms.exemple_rectangle_4.text && (
                      <TitleWithColors
                        as="p"
                        fontSize="xl"
                        dangerouslySetInnerHTML={{
                          __html: cms.exemple_rectangle_4.text
                        }}
                      />
                    )}
                  </Stack>
                ))
              }
              {
                (cms.titre_rectangle_5.text,
                cms.description_rectangle_5.text && (
                  <Stack direction="column" spacing={6} shouldWrapChildren>
                    <Heading
                      as="h5"
                      backgroundColor="brand.400"
                      fontSize="xl"
                      display="inline-flex"
                      px={3}
                      py={1}
                    >
                      {cms.titre_rectangle_5.text}
                    </Heading>
                    <Text fontSize="xl">
                      {cms.description_rectangle_5.text}
                    </Text>
                    {cms.exemple_rectangle_5.text && (
                      <TitleWithColors
                        as="p"
                        fontSize="xl"
                        dangerouslySetInnerHTML={{
                          __html: cms.exemple_rectangle_5.text
                        }}
                      />
                    )}
                  </Stack>
                ))
              }
              {
                (cms.titre_rectangle_6.text,
                cms.description_rectangle_6.text && (
                  <Stack direction="column" spacing={6} shouldWrapChildren>
                    <Heading
                      as="h5"
                      backgroundColor="brand.400"
                      fontSize="xl"
                      display="inline-flex"
                      px={3}
                      py={1}
                    >
                      {cms.titre_rectangle_6.text}
                    </Heading>
                    <Text fontSize="xl">
                      {cms.description_rectangle_6.text}
                    </Text>
                    {cms.exemple_rectangle_6.text && (
                      <TitleWithColors
                        as="p"
                        fontSize="xl"
                        dangerouslySetInnerHTML={{
                          __html: cms.exemple_rectangle_6.text
                        }}
                      />
                    )}
                  </Stack>
                ))
              }
            </Stack>
          </ContainerChakra>
        </Box>

        <Box backgroundColor="brand.400">
          <ContainerChakra maxWidth="6xl" py={10}>
            <Flex direction={{ base: 'column', md: 'row-reverse' }}>
              <Box flex={1}>
                <GatsbyImage
                  as={ChakraImage}
                  width="100%"
                  display={{ base: 'none', lg: 'block' }}
                  fluid={cms.img_banniere.localFile?.childImageSharp.fluid}
                />
              </Box>

              <Box flex={1} mr={{ base: 0, md: 6 }} mt={{ base: 6, md: 0 }}>
                <Text color="white" fontSize="2xl" fontWeight="bold" mb={6}>
                  {cms.texte_banniere_1.text}
                </Text>
                <Button
                  as="a"
                  href={cms.url_bouton.url}
                  backgroundColor="brand.400"
                  _hover="none"
                  textTransform="uppercase"
                  color="white"
                  border="2px solid"
                  borderColor="white"
                >
                  {cms.texte_bouton.text}
                </Button>
              </Box>
            </Flex>
          </ContainerChakra>
        </Box>

        {cms.texte_section_3.html && (
          <Box>
            <Flex alignItems="center">
              <Box flex={2}>
                <ContainerChakra maxWidth="4xl">
                  {cms.titre_section_3.text && (
                    <TitleWithColors
                      fontSize="28px"
                      fontWeight="bold"
                      mb={8}
                      dangerouslySetInnerHTML={{
                        __html: cms.titre_section_3.text
                      }}
                    />
                  )}

                  <Box
                    fontSize="lg"
                    mb={5}
                    sx={{
                      h3: {
                        fontSize: 28,
                        marginBottom: '1em'
                      },
                      p: {
                        marginBottom: '1em',
                        marginTop: '1em'
                      },
                      a: {
                        color: 'brand.400'
                      },
                      'ul,ol': {
                        paddingLeft: '2rem'
                      },
                      'li + li': {
                        marginTop: '.5em'
                      },
                      'li::marker': {
                        color: 'brand.400',
                        fontWeight: 'bold',
                        fontSize: '22px'
                      }
                    }}
                    dangerouslySetInnerHTML={{
                      __html: cms.texte_section_3.html
                    }}
                  />
                </ContainerChakra>
              </Box>
              {cms.img_section_3?.document?.data.image_section.localFile
                ?.childImageSharp.fluid && (
                <Center flex={1} display={{ base: 'none', lg: 'block' }}>
                  <Box w="90%">
                    <GatsbyImage
                      style={{ borderRadius: '12px' }}
                      as={ChakraImage}
                      fluid={
                        cms.img_section_3.document.data.image_section.localFile
                          ?.childImageSharp.fluid
                      }
                    />
                  </Box>
                </Center>
              )}
            </Flex>
          </Box>
        )}
        {cms.texte_section_4.html && (
          <Box>
            <Flex alignItems="center">
              <Box flex={2}>
                <ContainerChakra maxWidth="4xl">
                  {cms.titre_section_4.text && (
                    <TitleWithColors
                      fontSize="28px"
                      fontWeight="bold"
                      mb={8}
                      dangerouslySetInnerHTML={{
                        __html: cms.titre_section_4.text
                      }}
                    />
                  )}

                  <Box
                    fontSize="lg"
                    mb={5}
                    sx={{
                      h3: {
                        fontSize: 28,
                        marginBottom: '1em'
                      },
                      p: {
                        marginBottom: '1em',
                        marginTop: '1em'
                      },
                      a: {
                        color: 'brand.400'
                      },
                      'ul,ol': {
                        paddingLeft: '2rem'
                      },
                      'li + li': {
                        marginTop: '.5em'
                      },
                      'li::marker': {
                        color: 'brand.400',
                        fontWeight: 'bold',
                        fontSize: '22px'
                      }
                    }}
                    dangerouslySetInnerHTML={{
                      __html: cms.texte_section_4.html
                    }}
                  />
                </ContainerChakra>
              </Box>
              {cms.img_section_4?.document?.data.image_section.localFile
                ?.childImageSharp.fluid && (
                <Center flex={1} display={{ base: 'none', lg: 'block' }}>
                  <Box w="90%">
                    <GatsbyImage
                      style={{ borderRadius: '12px' }}
                      as={ChakraImage}
                      fluid={
                        cms.img_section_4.document.data.image_section.localFile
                          ?.childImageSharp.fluid
                      }
                    />
                  </Box>
                </Center>
              )}
            </Flex>
          </Box>
        )}
        {cms.texte_section_5.html && (
          <Box>
            <Flex alignItems="center">
              {cms.img_section_5.document.data.image_section.localFile
                ?.childImageSharp.fluid && (
                <Center
                  flex={1}
                  display={{ base: 'none', lg: 'block' }}
                  px={24}
                >
                  <GatsbyImage
                    style={{ borderRadius: '12px' }}
                    as={ChakraImage}
                    fluid={
                      cms.img_section_5.document.data.image_section.localFile
                        ?.childImageSharp.fluid
                    }
                  />
                </Center>
              )}
              <Box flex={2}>
                <ContainerChakra maxWidth="4xl">
                  <Box
                    fontSize="lg"
                    mb={5}
                    sx={{
                      h3: {
                        fontSize: 28,
                        marginBottom: '1em'
                      },
                      p: {
                        marginBottom: '1em',
                        marginTop: '1em'
                      },
                      a: {
                        color: 'brand.400'
                      },
                      'ul,ol': {
                        paddingLeft: '2rem'
                      },
                      'li + li': {
                        marginTop: '.5em'
                      },
                      'li::marker': {
                        color: 'brand.400',
                        fontWeight: 'bold',
                        fontSize: '22px'
                      }
                    }}
                    dangerouslySetInnerHTML={{
                      __html: cms.texte_section_5.html
                    }}
                  />
                </ContainerChakra>
              </Box>
            </Flex>
          </Box>
        )}
        {cms.texte_section_6.html && (
          <Box>
            <ContainerChakra
              maxWidth="8xl"
              border="3px solid"
              borderColor="brand.400"
              borderRadius="10px"
              py={10}
            >
              <ContainerChakra maxWidth="5xl">
                <Box
                  fontSize="18px"
                  sx={{
                    h3: {
                      fontSize: 28,
                      marginBottom: '1em'
                    },
                    p: {
                      marginBottom: '1em',
                      marginTop: '1em'
                    },
                    a: {
                      color: 'brand.400'
                    },
                    'ul,ol': {
                      paddingLeft: '2rem'
                    },
                    'li + li': {
                      marginTop: '.5em'
                    },
                    'li::marker': {
                      color: 'brand.400',
                      fontWeight: 'bold',
                      fontSize: '22px'
                    }
                  }}
                  dangerouslySetInnerHTML={{
                    __html: cms.texte_section_6.html
                  }}
                />
              </ContainerChakra>
            </ContainerChakra>
          </Box>
        )}
        {cms.texte_section_7.html && (
          <Box>
            <Flex>
              {cms.img_section_7.document.data.image_section.localFile
                ?.childImageSharp.fluid && (
                <Center flex={1}>
                  <Box w="80%">
                    <GatsbyImage
                      style={{ borderRadius: '12px' }}
                      as={ChakraImage}
                      display={{ base: 'none', lg: 'block' }}
                      fluid={
                        cms.img_section_7.document.data.image_section.localFile
                          ?.childImageSharp.fluid
                      }
                    />
                  </Box>
                </Center>
              )}
              <Box flex={2}>
                <ContainerChakra maxWidth="4xl">
                  <Box
                    fontSize="lg"
                    mb={5}
                    sx={{
                      h3: {
                        fontSize: 28,
                        marginBottom: '1em'
                      },
                      p: {
                        marginBottom: '1em',
                        marginTop: '1em'
                      },
                      a: {
                        color: 'brand.400'
                      },
                      'ul,ol': {
                        paddingLeft: '2rem'
                      },
                      'li + li': {
                        marginTop: '.5em'
                      },
                      'li::marker': {
                        color: 'brand.400',
                        fontWeight: 'bold',
                        fontSize: '22px'
                      }
                    }}
                    dangerouslySetInnerHTML={{
                      __html: cms.texte_section_7.html
                    }}
                  />
                </ContainerChakra>
              </Box>
            </Flex>
          </Box>
        )}
        {cms.texte_section_8.html && (
          <Box>
            <ContainerChakra
              maxWidth="8xl"
              border="3px solid"
              borderColor="brand.400"
              borderRadius="10px"
              py={10}
            >
              <ContainerChakra maxWidth="5xl">
                <Box
                  fontSize="18px"
                  sx={{
                    h3: {
                      fontSize: 28,
                      marginBottom: '1em'
                    },
                    p: {
                      marginBottom: '1em',
                      marginTop: '1em'
                    },
                    a: {
                      color: 'brand.400'
                    },
                    'ul,ol': {
                      paddingLeft: '2rem'
                    },
                    'li + li': {
                      marginTop: '.5em'
                    },
                    'li::marker': {
                      color: 'brand.400',
                      fontWeight: 'bold',
                      fontSize: '22px'
                    }
                  }}
                  dangerouslySetInnerHTML={{
                    __html: cms.texte_section_8.html
                  }}
                />
              </ContainerChakra>
            </ContainerChakra>
          </Box>
        )}

        <Box>
          <ContainerChakra
            maxWidth="8xl"
            border="3px solid"
            borderColor="#F0F0F0"
            backgroundColor="#F0F0F0"
            borderRadius="10px"
            px={0}
            py={10}
          >
            <ContainerChakra maxWidth="4xl">
              <Center mb={4}>
                <ChakraImage
                  w={{ base: '25%', md: '20%', lg: '15%' }}
                  src="https://i.imgur.com/JHH2wgG.png"
                />
              </Center>
              <Box>
                <Text fontSize="25px" textAlign="center" fontWeight="bold">
                  {cms.titre_conclusion.text}
                </Text>
                <Box
                  fontSize="18px"
                  sx={{
                    p: {
                      marginBottom: '1em',
                      marginTop: '1em'
                    },

                    'ul,ol': {
                      paddingLeft: '2rem'
                    },
                    'li + li': {
                      marginTop: '.5em'
                    },
                    'li::marker': {
                      color: 'brand.400',
                      fontWeight: 'bold',
                      fontSize: '22px'
                    }
                  }}
                  dangerouslySetInnerHTML={{
                    __html: cms.description_conclusion.html
                  }}
                />
              </Box>
            </ContainerChakra>
          </ContainerChakra>
        </Box>

        <Box>
          <ContainerChakra maxWidth="8xl">
            <Text as="h3" fontSize="3xl" fontWeight="bold" pb={10}>
              Aller plus loin...
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap="1em">
              {cms.texte_lien_1.text && (
                <Box bg="brand.300" borderRadius="10px">
                  {cms.img_1.localFile?.childImageSharp?.fluid && (
                    <GatsbyImage
                      style={{
                        width: '100%',
                        height: '31vh',
                        objectFit: 'cover',
                        borderRadius: '10px 10px 0px 0px',
                        opacity: '0.8'
                      }}
                      as={ChakraImage}
                      width="100%"
                      display={{ base: 'none', lg: 'block' }}
                      fluid={cms.img_1.localFile.childImageSharp.fluid}
                    />
                  )}

                  <Box
                    maxWidth="90%"
                    textAlign="center"
                    ml={5}
                    mt="17px"
                    mb="17px"
                  >
                    <Box
                      fontWeight="semibold"
                      fontSize="18px"
                      textDecoration="underline"
                      dangerouslySetInnerHTML={{
                        __html: cms.texte_lien_1.html
                      }}
                    />
                  </Box>
                </Box>
              )}
              {cms.texte_lien_2.text && (
                <Box bg="brand.300" borderRadius="10px">
                  {cms.img_2.localFile?.childImageSharp.fluid && (
                    <GatsbyImage
                      style={{
                        width: '100%',
                        height: '31vh',
                        objectFit: 'cover',
                        borderRadius: '10px 10px 0px 0px',
                        opacity: '0.8'
                      }}
                      as={ChakraImage}
                      width="100%"
                      display={{ base: 'none', lg: 'block' }}
                      fluid={cms.img_2.localFile.childImageSharp.fluid}
                    />
                  )}
                  <Box
                    maxWidth="90%"
                    textAlign="center"
                    ml={5}
                    mt="17px"
                    mb="17px"
                  >
                    <Box
                      fontWeight="semibold"
                      fontSize="18px"
                      textDecoration="underline"
                      dangerouslySetInnerHTML={{
                        __html: cms.texte_lien_2.html
                      }}
                    />
                  </Box>
                </Box>
              )}
              {cms.texte_lien_3.text && (
                <Box bg="brand.300" borderRadius="10px">
                  {cms.img_3.localFile.childImageSharp.fluid && (
                    <GatsbyImage
                      style={{
                        width: '100%',
                        height: '31vh',
                        objectFit: 'cover',
                        borderRadius: '10px 10px 0px 0px',
                        opacity: '0.8'
                      }}
                      as={ChakraImage}
                      width="100%"
                      display={{ base: 'none', lg: 'block' }}
                      fluid={cms.img_3.localFile.childImageSharp.fluid}
                    />
                  )}
                  <Box
                    maxWidth="90%"
                    textAlign="center"
                    ml={5}
                    mt="17px"
                    mb="17px"
                  >
                    <Box
                      fontWeight="semibold"
                      fontSize="18px"
                      textDecoration="underline"
                      dangerouslySetInnerHTML={{
                        __html: cms.texte_lien_3.html
                      }}
                    />
                  </Box>
                </Box>
              )}
              {cms.texte_lien_4.text && (
                <Box bg="brand.300" borderRadius="10px">
                  {cms.img_4.localFile.childImageSharp.fluid && (
                    <GatsbyImage
                      style={{
                        width: '100%',
                        height: '31vh',
                        objectFit: 'cover',
                        borderRadius: '10px 10px 0px 0px',
                        opacity: '0.8'
                      }}
                      as={ChakraImage}
                      width="100%"
                      display={{ base: 'none', lg: 'block' }}
                      fluid={cms.img_4.localFile.childImageSharp.fluid}
                    />
                  )}
                  <Box
                    maxWidth="90%"
                    textAlign="center"
                    ml={5}
                    mt="17px"
                    mb="17px"
                  >
                    <Box
                      fontWeight="semibold"
                      fontSize="18px"
                      textDecoration="underline"
                      dangerouslySetInnerHTML={{
                        __html: cms.texte_lien_4.html
                      }}
                    />
                  </Box>
                </Box>
              )}
              {cms.texte_lien_5.text && (
                <Box bg="brand.300" borderRadius="10px">
                  {cms.img_5.localFile.childImageSharp.fluid && (
                    <GatsbyImage
                      style={{
                        width: '100%',
                        height: '31vh',
                        objectFit: 'cover',
                        borderRadius: '10px 10px 0px 0px',
                        opacity: '0.8'
                      }}
                      as={ChakraImage}
                      width="100%"
                      display={{ base: 'none', lg: 'block' }}
                      fluid={cms.img_5.localFile.childImageSharp.fluid}
                    />
                  )}
                  <Box
                    maxWidth="90%"
                    textAlign="center"
                    ml={5}
                    mt="17px"
                    mb="17px"
                  >
                    <Box
                      fontWeight="semibold"
                      fontSize="18px"
                      textDecoration="underline"
                      dangerouslySetInnerHTML={{
                        __html: cms.texte_lien_5.html
                      }}
                    />
                  </Box>
                </Box>
              )}
              {cms.texte_lien_6.text && (
                <Box bg="brand.300" borderRadius="10px">
                  {cms.img_6.localFile.childImageSharp.fluid && (
                    <GatsbyImage
                      style={{
                        width: '100%',
                        height: '31vh',
                        objectFit: 'cover',
                        borderRadius: '10px 10px 0px 0px',
                        opacity: '0.8'
                      }}
                      as={ChakraImage}
                      width="100%"
                      display={{ base: 'none', lg: 'block' }}
                      fluid={cms.img_6.localFile.childImageSharp.fluid}
                    />
                  )}
                  <Box
                    maxWidth="90%"
                    textAlign="center"
                    ml={5}
                    mt="17px"
                    mb="17px"
                  >
                    <Box
                      fontWeight="semibold"
                      fontSize="18px"
                      textDecoration="underline"
                      dangerouslySetInnerHTML={{
                        __html: cms.texte_lien_6.html
                      }}
                    />
                  </Box>
                </Box>
              )}
            </SimpleGrid>
          </ContainerChakra>
        </Box>

        <Box bg="#fae9a6">
          <Flex>
            <Center flex={1}>
              <ContainerChakra maxWidth="30rem" textAlign="center" py={10}>
                <Text
                  textTransform="uppercase"
                  fontSize="2xl"
                  fontWeight="600"
                  mb={6}
                  lineHeight="short"
                >
                  {cms.texte_banniere_cta.text}
                </Text>
                <Button
                  as="a"
                  href={cms.url_bouton_cta.url}
                  boxShadow="3px 3px 3px rgb(0 0 0 / 50%)"
                  backgroundColor="brand.400"
                  fontSize="lg"
                  _hover="none"
                  textTransform="uppercase"
                  size="lg"
                  whiteSpace="normal"
                >
                  {cms.texte_bouton_cta.text}
                </Button>
              </ContainerChakra>
            </Center>
            <Box flex={1} display={{ base: 'none', lg: 'block' }}>
              <GatsbyImage
                as={ChakraImage}
                width="100%"
                ratio={4 / 3}
                fluid={{
                  ...cms.img_banniere_cta.localFile?.childImageSharp.fluid,
                  aspectRatio: 1.61803398875
                }}
              />
            </Box>
          </Flex>
        </Box>
      </Stack>
    </Layout>
  );
};

export const query = graphql`
  query PageProfondeQuery($id: String!) {
    prismicPageProfonde(id: { eq: $id }) {
      id
      data {
        description_section_1 {
          html
          text
        }
        description_conclusion {
          html
        }
        description_intro {
          html
          text
        }
        description_rectangle_1 {
          html
          text
        }
        description_rectangle_2 {
          html
          text
        }
        description_rectangle_3 {
          html
          text
        }
        description_rectangle_4 {
          html
        }
        description_rectangle_5 {
          html
        }
        description_rectangle_6 {
          html
        }
        description_section_1 {
          html
        }
        description_generale_section_2 {
          html
          text
        }
        exemple_rectangle_1 {
          html
          text
        }
        exemple_rectangle_2 {
          html
          text
        }
        exemple_rectangle_3 {
          html
          text
        }
        exemple_rectangle_4 {
          html
        }
        exemple_rectangle_5 {
          html
        }
        exemple_rectangle_6 {
          html
        }
        img_1 {
          localFile {
            childImageSharp {
              fluid(maxWidth: 4608) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        img_2 {
          localFile {
            childImageSharp {
              fluid(maxWidth: 5760) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        img_3 {
          localFile {
            childImageSharp {
              fluid(maxWidth: 5996) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        img_4 {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        img_5 {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        img_6 {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        img_banniere {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1045) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        img_banniere_cta {
          localFile {
            childImageSharp {
              fluid(maxWidth: 7000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        img_intro {
          localFile {
            childImageSharp {
              fluid(maxWidth: 7360) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        img_section_3 {
          document {
            ... on PrismicImageweb2vi {
              id
              data {
                image_section {
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
        img_section_4 {
          document {
            ... on PrismicImageweb2vi {
              id
              data {
                image_section {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 5000) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
        img_section_5 {
          document {
            ... on PrismicImageweb2vi {
              id
              data {
                image_section {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1800) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
        img_section_6 {
          document {
            ... on PrismicImageweb2vi {
              id
              data {
                image_section {
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
        img_section_7 {
          document {
            ... on PrismicImageweb2vi {
              id
              data {
                image_section {
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
        img_section_8 {
          document {
            ... on PrismicImageweb2vi {
              id
              data {
                image_section {
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
        sous_titre_intro {
          html
          text
        }
        texte_banniere_1 {
          html
          text
        }
        texte_banniere_cta {
          html
          text
        }
        texte_bouton {
          html
          text
        }
        texte_bouton_cta {
          html
          text
        }
        texte_lien_1 {
          html
          text
        }
        texte_lien_2 {
          html
          text
        }
        texte_lien_3 {
          html
          text
        }
        texte_lien_4 {
          html
          text
        }
        texte_lien_5 {
          html
          text
        }
        texte_lien_6 {
          html
          text
        }
        texte_section_3 {
          html
        }
        texte_section_4 {
          html
        }
        texte_section_5 {
          html
        }
        texte_section_6 {
          html
        }
        texte_section_7 {
          html
        }
        texte_section_8 {
          html
        }
        titre_carre_1 {
          html
          text
        }
        titre_carre_2 {
          html
          text
        }
        titre_carre_3 {
          html
          text
        }
        titre_carre_4 {
          html
          text
        }
        titre_carre_5 {
          html
          text
        }
        titre_carre_6 {
          html
          text
        }
        titre_conclusion {
          html
          text
        }
        titre_generale {
          html
          text
        }
        meta_description {
          text
        }
        titre_seo {
          text
        }
        titre_section_1 {
          html
          text
        }
        titre_section_2 {
          html
          text
        }
        titre_section_3 {
          html
          text
        }
        titre_section_4 {
          html
          text
        }
        titre_rectangle_1 {
          html
          text
        }
        titre_rectangle_2 {
          html
          text
        }
        titre_rectangle_3 {
          html
          text
        }
        titre_rectangle_4 {
          html
        }
        titre_rectangle_5 {
          html
        }
        titre_rectangle_6 {
          html
        }
        titre_section_1 {
          html
        }
        titre_section_2 {
          html
        }
        url_bouton {
          slug
          url
        }
        url_bouton_cta {
          slug
          url
        }
      }
    }
  }
`;

export default deepPage;
