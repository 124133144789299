import React from 'react';

import { Heading, useToken } from '@chakra-ui/react';
import styled from '@emotion/styled';

const TitleWithColorsContainer = styled(Heading)`
  span {
    color: ${props => props.spanColor};
  }
`;

export const TitleWithColors = ({
  title,
  as = 'h1',
  primaryColor = 'gray.900',
  secondaryColor = 'brand.400',
  ...rest
}) => {
  const [spanColor] = useToken('colors', [secondaryColor]);

  return (
    <TitleWithColorsContainer
      as={as}
      mb={5}
      dangerouslySetInnerHTML={{ __html: title }}
      color={primaryColor}
      spanColor={spanColor}
      {...rest}
    />
  );
};
